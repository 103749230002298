import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StateServices from '../../services/StateServices.js';
import Select from 'react-select';

function AddThana() {
    const [formValues, setFormValues] = useState({
        name: '',
        district: null, // Set the initial value to null for react-select
        code: '',
        email: '',
        circleName:'',
    });
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        const fetchDistricts = async () => {
            try {
                const res = await StateServices.getAllDistrict();
                if (res.status === true) {
                    const districtOptions = res.data.map(district => ({
                        label: district.name,
                        value: district._id
                    }));
                    setDistricts(districtOptions); // Set options in the format react-select expects
                } else {
                    alert('Failed to fetch district');
                }
            } catch (error) {
                console.error('Failed to fetch district', error);
                alert('Failed to fetch district');
            }
        };

        fetchDistricts();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOption) => {
        setFormValues({
            ...formValues,
            district: selectedOption // Store the whole option (object with label and value)
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await StateServices.createThana({ 
                name: formValues.name, 
                district: formValues.district.value, // Send the district ID to the backend
                code: formValues.code,
                email: formValues.email ,
                circleName:formValues.circleName
            });
            if (res.status === true) {
                alert('Thana added successfully');
                setFormValues({ name: '', district: null, code: '', email: '', circleName:'' }); // Reset the form values
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to add thana', error);
            alert('Failed to add thana');
        }
    };

    return (
        <div className="right_col" role="main">
            <div className="title-box">
                <h2>Thana Add</h2>
                <div className="container-box-top-header-right">
                    <Link className="list-banner-btn" to="/thana">Thana List</Link>
                </div>
            </div>
            <div className="container-box px-5">
                <div className="container-box-inner">
                    <div className="page-details">
                        <form onSubmit={handleSubmit}>
                            <div className="input-field">
                                <label>District Name*</label>
                                <Select
                                    name="district"
                                    className="form-control"
                                    value={formValues.district} // Set the selected option
                                    onChange={handleSelectChange} // Handle selection change
                                    options={districts} // Provide the options to react-select
                                    placeholder="Select a District"
                                    isSearchable
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label>Thana Name*</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label>Thana Code</label>
                                <input
                                    type="text"
                                    name="code"
                                    className="form-control"
                                    value={formValues.code}
                                    onChange={handleInputChange}
                                   
                                />
                            </div>
                            <div className="input-field">
                                <label>Thana Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                   
                                />
                            </div>
                            <div className="input-field">
                                <label>Circle Name</label>
                                <input
                                    type="text"
                                    name="circleName"
                                    className="form-control"
                                    value={formValues.circleName}
                                    onChange={handleInputChange}
                                   
                                />
                            </div>
                            <button className="submit-green-btn" type="submit">
                                SUBMIT
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddThana;

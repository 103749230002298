import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAsync from '../hooks/useAsync';
import StateServices from '../services/StateServices.js';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import UpdateDistrict from './Update Model/UpdateDistrict.js';

Modal.setAppElement('#root');

function District() {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);  // Current page state
  const [itemsPerPage] = useState(50);  // Items per page state

  const { data, error, isLoading, run } = useAsync(StateServices.getAllDistrict);

  const handleEditDetails = (district) => {
    setSelectedEdit(district);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (district) => {
    setSelectedEdit(district);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Filtered district data based on search term
  const filteredDistricts = data?.data?.filter((district) =>
    district.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  district.state.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  // Calculate total pages
  const totalPages = Math.ceil(filteredDistricts.length / itemsPerPage);

  // Get the paginated data
  const paginatedDistricts = filteredDistricts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>District List <span className="badge bg-orange">{filteredDistricts.length}</span></h2>
        <div className="container-box-top-header-right">
          <Link className="round-add-btn" to="/add-district">
            <img src="img/plus.svg" alt="Add" />
          </Link>
        </div>
      </div>
      <div className="container-box px-0">
        <div className="container-box-top-header px-4">
          <div className="container-box-top-header-left-2">
            <input type="search" name="search" placeholder="Search" value={searchTerm} onChange={handleSearchChange} />
            <button className="search-btn">Search</button>
          </div>
        </div>
        <div className="container-box-inner">
          <table id="example" className="table table-striped" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>State Name</th>
                <th>District Name</th>
                <th>District Code</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedDistricts.map((district, index) => (
                <tr key={district._id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{district?.state?.name}</td>
                  <td>{district?.name}</td>
                  <td>{district?.code || 'N/A'}</td>
                  <td>
                    <div className="action_toggle_outer">
                      <button
                        className="action_toggle"
                        onClick={() => toggleActionMenu(index)}
                      >
                        <i className="fa fa-ellipsis-v" />
                      </button>
                      {activeIndex === index && (
                        <div className="action_toggle_content product-edit show d-block">
                          <ul className="list-unstyled">
                            <li>
                              <button className="close_toggle" onClick={() => toggleActionMenu(null)}>
                                <i className="fa fa-close" />
                              </button>
                            </li>
                            <li>
                              <button className="edit" onClick={() => handleEditDetails(district)}>Edit</button>
                            </li>
                            <li>
                              <button className="reject" onClick={() => handleDelete(district)}>Delete</button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      {/* Pagination Controls */}
      <div className="pagination-controls text-center">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        {[...Array(totalPages)].map((_, pageIndex) => (
          <button
            key={pageIndex}
            className={pageIndex + 1 === currentPage ? 'active' : ''}
            onClick={() => handlePageChange(pageIndex + 1)}
          >
            {pageIndex + 1}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Category"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <UpdateDistrict selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <DeleteButton data={selectedEdit} page="district" closeModal={closeDeleteModal} onSuccess={run} />
      </Modal>
    </div>
  );
}

export default District;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserServices from '../../services/UserServices';

function AddOperator() {
  const [formValues, setFormValues] = useState({
    name: '',
    role: '',
    email: '',
    mobileNo: '',
    state: '',
    district: '',
    policeStation: '',
    rank: '',
    employeeNo: '',
    pincode: '',
    password: '',
    image: null,
  });

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [policeStations, setPoliceStations] = useState([]);
  const [previewImage, setPreviewImage] = useState('img/placeholder-img.png');

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await UserServices.getState();
        setStates(response.data);
      } catch (error) {
        console.error('Failed to fetch states', error);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      if (formValues.state) {
        try {
          const response = await UserServices.getDistrictByState(formValues.state);
          setDistricts(response.data);
        } catch (error) {
          console.error('Failed to fetch districts', error);
        }
      }
    };
    fetchDistricts();
  }, [formValues.state]);

  useEffect(() => {
    const fetchPoliceStations = async () => {
      if (formValues.district) {
        try {
          const response = await UserServices.getThanaByDistrict(formValues.district);
          setPoliceStations(response.data);
        } catch (error) {
          console.error('Failed to fetch police stations', error);
        }
      }
    };
    fetchPoliceStations();
  }, [formValues.district]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormValues({
        ...formValues,
        image: file,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    // Log formValues to debug
    console.log("Form Values:", formValues);

    for (const key in formValues) {
      if (formValues[key]) {
        formData.append(key, formValues[key]);
      }
    }

    // Log formData to check if it's populated correctly
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    try {
      const response = await UserServices.createAdmin(formData);
      if (response.status === 201) {
        alert('Operator user created successfully');
      } else {
        alert('Operator user created successfully');
      }
    } catch (error) {
      // console.error('Failed to create Operator user', error);
      // if (error.response && error.response.data && error.response.data.status === false) {
      //   alert('Email or Mobile number already exists.');
      // } else {
      //   alert('An error occurred while creating the Operator user.');
      // }
      console.error('Failed to create Admin user', error);

            // Check if the error is a duplicate key error
            if (error.response && error.response.data) {
                // Assuming MongoDB duplicate key error format
                const duplicateKeyError = error.response.data.message || '';
                if (duplicateKeyError.includes('duplicate key error') ||
                    duplicateKeyError.includes('dup key:')) {
                    alert('Email or Mobile number already exists.');
                } else {
                    alert('Email and Mobile number already exists: ' + duplicateKeyError);
                }
            } else {
                alert('An error occurred while creating the Admin user.');
            }
    }
    
   
  };

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Operator User Add</h2>
        <div className="container-box-top-header-right">
          <Link className="list-banner-btn" to="/operator-user">User List</Link>
        </div>
      </div>
      <div className="container-box px-5">
        <div className="container-box-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="page-details">
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          value={formValues.name}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          value={formValues.email}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Mobile No.</label>
                        <input
                          type="text"
                          name="mobileNo"
                          value={formValues.mobileNo}
                          onChange={handleInputChange}
                          className="form-control"
                          maxLength={10}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>State</label>
                        <select
                          className="form-control"
                          name="state"
                          value={formValues.state}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">--Select State--</option>
                          {states.map((state) => (
                            <option key={state._id} value={state._id}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>District</label>
                        <select
                          className="form-control"
                          name="district"
                          required
                          value={formValues.district}
                          onChange={handleInputChange}
                        >
                          <option value="">--Select District--</option>
                          {districts.map((district) => (
                            <option key={district._id} value={district._id}>
                              {district.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Police Station</label>
                        <select
                          className="form-control"
                          name="policeStation"
                          required
                          value={formValues.policeStation}
                          onChange={handleInputChange}
                        >
                          <option value="">--Select Police Station--</option>
                          {policeStations.map((policeStation) => (
                            <option key={policeStation._id} value={policeStation._id}>
                              {policeStation.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Pincode</label>
                        <input
                          type="text"
                          name="pincode"
                          maxLength={6}
                          value={formValues.pincode}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Rank</label>
                        <input
                          type="text"
                          name="rank"
                          value={formValues.rank}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Employee No.</label>
                        <input
                          type="text"
                          name="employeeNo"
                          value={formValues.employeeNo}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          maxLength={4}
                          value={formValues.password}
                          onChange={handleInputChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="input-field">
                        <label>Image</label>
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="form-control"
                        />
                      </div>
                      <div>
                        <img src={previewImage} alt="Preview" style={{ width: '100px', height: '100px' }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div >
                      <button type="submit" className="submit-green-btn" >
                        Submit
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOperator;

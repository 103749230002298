import requests from "./httpService";

const YogaCategoryServices = {

    getYogaCategory: async () =>{
        return requests.get(`/yoga-cat`);
    },
    updateYogaCategory : async (id,body) =>{
        return requests.put(`/yoga-cat/${id}`,body);
    },

    createYogaCategory : async (body) =>{
        console.log(body)
        return requests.post(`/yoga-cat`,body);
    },
    deleteYogaCategory : async (id) =>{
        return requests.delete(`/yoga-cat/${id}`);
    }
  


}
export default YogaCategoryServices;
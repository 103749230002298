import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  const name = localStorage.getItem("name");
  const image = localStorage.getItem("image");
  const ThanaName = localStorage.getItem("policeStation");
  const circleName = localStorage.getItem("circleName");

  // const allStorage = {};
  // for (let i = 0; i < localStorage.length; i++) {
  //   const key = localStorage.key(i);
  //   allStorage[key] = localStorage.getItem(key);
  // }
  // console.log("All localStorage items:", allStorage);
  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  }
  return (
    <>
      <div className="top_nav">
        <div className="nav_menu">
          <nav>
            <div className="nav toggle">
              {/* <a id="menu_toggle"><i className="fa fa-sign-in" /></a> */}
              <Link className="admin-profile" to={"/settings"}>
                <div className="admin-profile-img">
                  <img src={process.env.REACT_APP_URL + image || 'img/profile-img.png'} alt="Profile" style={{ height: "45px" }} />
                </div>
                <p>{name}</p>
                <p>{ThanaName ? ThanaName : circleName}</p>

              </Link>
            </div>
            <ul className="nav navbar-nav navbar-right">
              {/* <li className>
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#supportModal">
                    <img src="img/chat.svg" alt="" />
                  </a>
                </li> */}
              <li className>
                <a href="#" data-bs-toggle="modal" data-bs-target="#confirmationModel" >
                  <img src="img/logout.svg" alt="" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        className="modal fade support-modal"
        id="supportModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {/* <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Support</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="modal-body px-5 pb-0">
        <div className="row align-items-center">
          <div className="col-md-5">
            <img src="img/support-img.svg" alt="" />
          </div>
          <div className="col-md-7">
            <ul className="list-unstyled">
              <li>
                <span>
                  <i className="fa fa-phone" />
                </span>
                <a href="tel:+91 9057801818">+91 9057801818</a>
              </li>
              <li>
                <span>
                  <i className="fa fa-envelope" />
                </span>
                <a href="mailto:info@truevalueinfosoft.com">
                  info@truevalueinfosoft.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> */}
      </div>


      <div
        className="modal fade support-modal"
        id="confirmationModel"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Log Out</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body px-5 pb-0">
              <div className="row align-items-center">
                <div className="modal-body py-4 px-5 text-center">
                  <h4>Warning !!</h4>
                  <p className="desc my-2">Are you sure you want to Log Out ?</p>
                  <div className="donate-btn-grp text-center py-4">
                    <button className="site-btn btn-blue delete-btn "
                      data-bs-dismiss="modal"
                      aria-label="Close">No</button>
                    <button className="site-btn btn-red delete-btn" onClick={logOut}>Yes </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>



  )
}

export default Header

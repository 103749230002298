import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StateServices from '../../services/StateServices.js';

function UpdateState({ selectedData, closeModal, onSuccess }) {
    const [formValues, setFormValues] = useState({
        name: '',
        code: '',
        state: ''
    });
    const [states, setStates] = useState([]); // Initialize local state for states

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const res = await StateServices.getState();
                if (res.status === true) {
                    setStates(res.data); // Update local state with fetched states
                } else {
                    alert('Failed to fetch states');
                }
            } catch (error) {
                console.error('Failed to fetch states', error);
                alert('Failed to fetch states');
            }
        };
        fetchStates(); // Fetch states when the component mounts
    }, []);

    useEffect(() => {
        if (selectedData) {
            setFormValues({
                name: selectedData.name || '',
                code: selectedData.code || '',
                state: selectedData.state._id || '',  // Set the state ID here
            });
        }
    }, [selectedData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setFormValues({
            ...formValues,
            [name]: capitalizedValue
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await StateServices.updateDistrict(selectedData._id, { name: formValues.name, state: formValues.state, code: formValues.code });
            if (res.status === true) {
                alert('District updated successfully');
                onSuccess();
                closeModal();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            console.error('Failed to update district', error);
            alert('Failed to update district');
        }
    };

    return (
        <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit District</h5>
                        <button type="button" className="btn-close" onClick={closeModal} aria-label="Close" />
                    </div>
                    <div className="modal-body px-5 pb-0">
                        <div className="container-box px-5">
                            <div className="container-box-inner">
                                <div className="page-details">
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-field">
                                            <label>State Name*</label>
                                            <select
                                                name="state"
                                                className="form-control"
                                                value={formValues.state}  // This binds the selected state to the form value
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select a state</option>  {/* Placeholder option */}
                                                {states.map((state) => (
                                                    <option key={state._id} value={state._id}>
                                                        {state.name}  {/* Display the name of the state */}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="input-field">
                                            <label>District Name*</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                value={formValues.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="input-field">
                                            <label>District Code</label>
                                            <input
                                                type="text"
                                                name="code"
                                                className="form-control"
                                                value={formValues.code}
                                                onChange={handleInputChange}
                                               
                                            />
                                        </div>
                                        <button className="submit-green-btn" type="submit">
                                            SUBMIT
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UpdateState.propTypes = {
    selectedData: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default UpdateState;

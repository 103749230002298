import React, { useState, useEffect } from 'react';
import useAsync from '../hooks/useAsync';
import AdminServices from '../services/AdminServices';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import { Link } from 'react-router-dom';
import UpdateAdmin from './Update Model/UpdateAdminUser';

Modal.setAppElement('#root');

function AdminUser() {
  const { data, error, isLoading, run } = useAsync(AdminServices.getAllAdmin);
  //   console.log(">>>>>",data)
  const [admins, setAdmins] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  useEffect(() => {
    if (data && data.status) {
      //   console.log("Fetched data:", data);
      setAdmins(data.data);
    } else {
      console.error("Failed to fetch data or no data available:", error);
    }
  }, [data, error]);

  // useEffect(() => {
  //   run();
  // }, [run]);

  const handleEditDetails = (admin) => {
    setSelectedEdit(admin);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (admin) => {
    setSelectedEdit(admin);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Admin Users <span className="badge bg-orange">{admins.length}</span></h2>
        <div className="container-box-top-header-right">
          <Link className="round-add-btn" to="/add-admin-user"><img src="img/plus.svg" alt="Add Admin" /></Link>
        </div>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          {/* <p>Admin Users</p> */}
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4"></div>
        <div className="container-box-inner">
          {admins.length > 0 ? (
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Role Type</th>
                  <th>Name</th>
                  <th>Mobile No.</th>
                  <th>State</th>
                  <th>District</th>
                  <th>Police Station</th>
                  <th>CircleName</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Pincode</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {admins.map((admin, index) => (
                  <tr key={admin._id}>
                    <th>{index + 1}</th>
                    <td>{admin?.role || 'NA'}</td>
                    <td>{admin?.name || 'NA'}</td>
                    <td>{admin?.mobileNo || 'NA'}</td>
                    <td>{admin?.state?.name || 'NA'}</td>
                    <td>{admin?.district?.name || 'NA'}</td>
                    <td>{admin?.policeStation?.name || 'NA'}</td>
                    <td>{admin?.circleName || 'NA'}</td>
                    {/* <td>
                      {admin?.policeStation?.length > 0 ? (
                        admin.policeStation.map((station, idx) => (
                          <span key={idx}>
                            {station.name}
                            {idx !== admin.policeStation.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span>No Police Station</span>
                      )}
                    </td> */}
                    <td>{admin?.email}</td>

                    <td>{formatDateTime(admin?.createdAt)}</td>
                    <td>{admin.pincode}</td>
                    <td>
                      <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(admin)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(admin)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No admins found.</div>
          )}
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <UpdateAdmin selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="adminUser" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </div>
  );
}

export default AdminUser;

import React from 'react'

function ViewUser({ user  , closeModal}) {
  console.log(">>>>>>>>",user);
  if (!user) return null;
  function formatDateTime(isoString) {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  }

  const url = 'uploads/'
  const { date, time } = formatDateTime(user.createdAt);
  return (
    <div className="modal fade viewbox edit-box show d-block"  id="manufacturerModal" tabIndex={-1} aria-labelledby="manufacturerModal" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="manufacturerModal">Details</h5>
              <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <div className="modal-body px-5">
              <div className="d-box mt-3 pt-1">
                <ul className="d-flex list-unstyled justify-content-between">
                <li><span>Name: </span>{user.name}</li>
                <li><span>Mobile No: </span> {user.mobileNo}</li>
                <li><span>State: </span> {user.state}</li>
                <li><span>District: </span> {user?.district}</li>
                <li><span>City: </span> {user?.city}</li>
                {/* <li><span>Village: </span> {user?.village}</li> */}
                {/* <li><span>Block: </span> {user?.block}</li> */}
                {/* <li><span>Ward: </span> {user?.ward}</li> */}
                <li><span>Age: </span> {user?.age}</li>
                {/* <li><span>Other: </span> {user?.other}</li> */}
                <li><span>Fee :</span>{user?.fee}</li>
                <li><span>Patient :</span>{user?.patient}</li>
                <li><span>Experience :</span>{user?.experience}</li>
                <li><span>Registration date: </span>{date}</li>
                <div style={{ width: '100%' }}>
                  <li><span>Address: </span>{user?.address}</li>
                </div>
                  {/* {user.userType !== 'customer' && (
                  <>
                  <li><span>Details about {user.userType}: </span>{user?.profile?.detailAbout}</li>
                  <li><span>GST No* :   </span>{user?.profile?.gstNo}</li> 
                  <li><span>CIN No. : </span>{user?.profile?.cin}</li>
                  </>)}
                  {user.userType == 'serviceCenter' && (
                  <>
                  <li><span>Company Authorized : </span>{user?.profile?.authorization}</li>
                  </>
                )} */}
                  {/* <li><span>Payment :</span>{user?.plan?.planName}</li>
                  <li><span>Fee :</span>{user?.plan?.planName}</li> */}
                </ul>
                {/* <div className="address mt-4">
                  <span><a href="#"><img src="img/google-map-icon.svg" /></a></span>
                  <span>{user?.address}</span>
                </div> */}
                {user.userType !== 'customer' && (
                  <>
                  
                <div className="mt-5 d-box-grp">
                <a href={user?.yogaPoses?.pose1 ? `${process.env.REACT_APP_URL}${user.yogaPoses.pose1}` : 'img/profile-img.png'} target='_blank' rel="noopener noreferrer"><button className="document-update-btn"><img src="img/pdf.svg" alt="Yoga Pose 1" /> Yoga Pose 1* </button></a>
                <a href={user?.yogaPoses?.pose2 ? `${process.env.REACT_APP_URL}${user.yogaPoses.pose2}` : 'img/profile-img.png'} target='_blank' rel="noopener noreferrer"><button className="document-update-btn"><img src="img/pdf.svg" alt="Yoga Pose 2" /> Yoga Pose 2*</button></a>
                <a href={user?.yogaPoses?.pose3 ? `${process.env.REACT_APP_URL}${user.yogaPoses.pose3}` : 'img/profile-img.png'} target='_blank' rel="noopener noreferrer"><button className="document-update-btn"><img src="img/pdf.svg" alt="Yoga Pose 3" /> Yoga Pose 3*</button></a>
                <a href={user?.yogaPoses?.pose4 ? `${process.env.REACT_APP_URL}${user.yogaPoses.pose4}` : 'img/profile-img.png'} target='_blank' rel="noopener noreferrer"><button className="document-update-btn"><img src="img/pdf.svg" alt="Yoga Pose 4" /> Yoga Pose 4*</button></a>
                </div>
                </>)}
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ViewUser
import requests from "./httpService";

const StateServices = {

    getState: async () =>{
        return requests.get(`/state`);
    },
    updateState : async (id,body) =>{
        return requests.patch(`/state/${id}`,body);
    },

    createState : async (body) =>{
        console.log(body)
        return requests.post(`/state`,body);
    },
    deleteState : async (id) =>{
        return requests.delete(`/state/${id}`);
    },
    getDistrict: async (id) =>{
        return requests.get(`/state/${id}`);
    },
    getAllDistrict: async (id) =>{
        return requests.get(`/state/district`);
    },
    // getAllDistrict: async (page = 1, itemsPerPage = 40) => {
    //     return requests.get(`/state/district`, {
    //         params: {
    //             page,
    //             limit: itemsPerPage,
    //         },
    //     });
    // },
    updateDistrict : async (id,body) =>{
        return requests.patch(`/state/district/${id}`,body);
    },

    createDistrict : async (body) =>{
        return requests.post(`/state/district`,body);
    },
    deleteDistrict : async (id) =>{
        return requests.delete(`/state/district/${id}`);
    },
    getAllThana: async (id) =>{
        return requests.get(`/state/Thana`);
    },
   
    updateThana : async (id,body) =>{
        return requests.patch(`/state/Thana/${id}`,body);
    },

    createThana : async (body) =>{
        console.log(body)
        return requests.post(`/state/Thana`,body);
    },
    deleteThana : async (id) =>{
        return requests.delete(`/state/Thana/${id}`);
    }
  


}
export default StateServices;
import React, { useState, useEffect } from 'react';
import useAsync from '../hooks/useAsync';
import UserServices from '../services/UserServices';
import Modal from 'react-modal';
import DeleteButton from './Button/DeleteButton';
import { Link } from 'react-router-dom';
import UpdateAdmin from './Update Model/UpdateAdmin';

Modal.setAppElement('#root');

function OperatorUser() {
  const { data, error, isLoading, run } = useAsync(UserServices.getAllAdmin);
  const [admins, setAdmins] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null);

  useEffect(() => {
    if (data && data.status) {
      // console.log("Fetched data:", data);
      setAdmins(data.data);
    } else {
      console.error("Failed to fetch data or no data available:", error);
    }
  }, [data, error]);

  // useEffect(() => {
  //   run();
  // }, [run]);

  const handleEditDetails = (admin) => {
    setSelectedEdit(admin);
    setIsEditModalOpen(true);
    toggleActionMenu(null);
  };

  const handleDelete = (admin) => {
    setSelectedEdit(admin);
    setIsDeleteModalOpen(true);
    toggleActionMenu(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedEdit(null);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedEdit(null);
  };

  const toggleActionMenu = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function formatDateTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <div className="right_col" role="main">
      <div className="title-box">
        <h2>Operator Users <span className="badge bg-orange">{admins.length}</span></h2>
        <div className="container-box-top-header-right">
          <Link className="round-add-btn" to="/add-operator-user"><img src="img/plus.svg" alt="Add Admin" /></Link>
        </div>
      </div>
      <div className="sub-title-box">
        <div className="sub-title-box-left">
          {/* <p>Operator Users</p> */}
        </div>
      </div>
      <div className="container-box px-0 user-manager">
        <div className="container-box-top-header px-4"></div>
        <div className="container-box-inner">
          {admins.length > 0 ? (
            <table id="example" className="table table-striped" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>#</th>
                 
                  <th>Name</th>
                  <th>Image</th>
                  <th>Mobile No.</th>
                  <th>State</th>
                  <th>District</th>
                  <th>Police Station</th>
                  <th>Email</th>
                  <th>Employee No</th>
                  <th>Rank</th>
                  <th>Date</th>
                  <th>Pincode</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {admins.map((admin, index) => (
                  <tr key={admin._id}>
                    <th>{index + 1}</th>
                    <td>
                      <div className="product-img">
                        <img src={process.env.REACT_APP_URL + admin?.image} alt="" />
                      </div>
                    </td>
                    <td>{admin.name}</td>
                    <td>{admin.mobileNo}</td>
                    <td>{admin?.state?.name}</td>
                    <td>{admin?.district?.name}</td>
                    <td>{admin?.policeStation?.name}</td>
                    <td>{admin?.email}</td>
                    <td>{admin?.employeeNo}</td>
                    <td>{admin?.rank}</td>
                    <td>{formatDateTime(admin.createdAt)}</td>
                    <td>{admin.pincode}</td>
                    <td>
                      <div className="action_toggle_outer">
                        <button
                          className="action_toggle"
                          onClick={() => toggleActionMenu(index)}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        {activeIndex === index && (
                          <div className="action_toggle_content product-edit show d-block">
                            <ul className="list-unstyled">
                              <li><button className="close_toggle" onClick={() => toggleActionMenu(null)}><i className="fa fa-close" /></button></li>
                              <li><button className="edit" onClick={() => handleEditDetails(admin)} >Edit</button></li>
                              <li><button className="reject" onClick={() => handleDelete(admin)} >Delete</button></li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No admins found.</div>
          )}
        </div>
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="User Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <UpdateAdmin selectedData={selectedEdit} closeModal={closeEditModal} onSuccess={run} />
        </Modal>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={closeDeleteModal}
          contentLabel="Delete Confirmation"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <DeleteButton data={selectedEdit} page="Admin" closeModal={closeDeleteModal} onSuccess={run} />
        </Modal>
      </div>
    </div>
  );
}

export default OperatorUser;

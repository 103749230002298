import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import AdminServices from '../../services/AdminServices';

function Login({ setIsAuthenticated, setRole }) {
  const [mobileOrEmail, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation
    if (password.length < 8) {
    
      return;
    }

    try {
      // const hashedPassword = CryptoJS.SHA256(password).toString();
      const data = await AdminServices.login({ mobileOrEmail,  password });

      if (data.status === true) {
        // Store the token securely
        localStorage.setItem('authToken', data?.token); // Store JWT token
        localStorage.setItem('userRole', data?.data?.role);
        localStorage.setItem('name', data?.data?.name);
        localStorage.setItem('image', data?.data?.image);
        localStorage.setItem('circleName', data?.data?.circleName);
        localStorage.setItem('policeStation', data?.data?.policeStation?.name || '');

        setIsAuthenticated(true);
        setRole(data?.data?.role);

        // Redirect based on the role
        const role = data?.data?.role;
        navigate(role === 'User' || role === 'CO' ? '/dashboardUser' : '/dashboard');
      } else {
        setError(data?.message || '*Invalid Credentials');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('*Something went wrong. Please try again.');
      }
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="login-section">
      <div className="login-section-inner">
        <div className="row">
          <div className="col-md-6 pe-0">
            <div className="login-section-left">
              <h1>Login</h1>
              <p>Welcome to the Admin Panel!</p>
              <form className="user-login">
                <div className="input-field mb-3">
                  <label htmlFor="userEmail" className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="userEmail"
                    placeholder="example@example.com"
                    value={mobileOrEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-field mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"  // This ensures the password is masked
                    className="form-control"
                    id="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {error && <h6 style={{ color: 'red' }}>{error}</h6>}
                <div className="input-field mb-3">
                  <input
                    onClick={handleSubmit}
                    type="submit"
                    className="form-control"
                    id="login-btn"
                    value="Login"
                  />
                </div>
              </form>
              <h6 className="text-center">Version 1.0.0</h6>
            </div>
          </div>
          <div className="col-md-6 ps-0">
            <div className="login-section-right">
              <div className="logo-field">
                <img src="img/ukPolice.png" alt="Logo" />
                <h2 id="pehchaan" className="text-center h2 text-bold">Pehchaan</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserServices from '../../services/UserServices';
import AdminServices from '../../services/AdminServices';

function UpdateOperator({ selectedData, closeModal, onSuccess }) {
    console.log(">>", selectedData);
    const [formValues, setFormValues] = useState({
        name: '',
        role: '',
        email: '',
        mobileNo: '',
        state: '',
        district: '',
        policeStation: '',
        pincode: '',
        // rank: '',
        circleName: '',
        password: '',

    });
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [policeStations, setpoliceStations] = useState([]);
    const [circle, setCircle] = useState([]);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await UserServices.getState();
                setStates(response.data);
            } catch (error) {
                console.error('Failed to fetch states', error);
            }
        };
        fetchStates();
    }, []);

    useEffect(() => {
        const fetchDistricts = async () => {
            if (formValues.state) {
                try {
                    const response = await UserServices.getDistrictByState(formValues.state);
                    setDistricts(response.data);
                } catch (error) {
                    console.error('Failed to fetch districts', error);
                }
            }
        };
        fetchDistricts();
    }, [formValues.state]);
    useEffect(() => {
        const fetchThanas = async () => {
            if (formValues.district) {
                try {
                    const response = await UserServices.getThanaByDistrict(formValues.district);
                    setpoliceStations(response.data);
                } catch (error) {
                    console.error('Failed to fetch thanas', error);
                }
            }
        };
        fetchThanas();
    }, [formValues.district]);


    useEffect(() => {
        const fetchCircle = async () => {
            if (formValues.district && formValues.role === 'CO') {
                try {
                    const response = await UserServices.getCircleByDistrict(formValues.district);
                    setCircle(response.data);
                } catch (error) {
                    console.error('Failed to fetch circle name', error);
                }
            }
        };
        fetchCircle();
    }, [formValues.district, formValues.role]);

    useEffect(() => {
        if (selectedData) {
            setFormValues({
                name: selectedData?.name || '',
                role: selectedData?.role || '',
                email: selectedData?.email || '',
                mobileNo: selectedData?.mobileNo || '',
                state: selectedData.state?._id || '',
                district: selectedData.district?._id || '',
                policeStation: selectedData?.policeStation?._id || '',
                circleName: selectedData?.circleName || '',
                pincode: selectedData?.pincode || '',
                // rank: selectedData?.rank || '',
                // employeeNo: selectedData?.employeeNo || '',
                // password: '', 
                password: selectedData?.password || '',

            });

        }
    }, [selectedData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };





    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prepare form data
        const formData = new FormData();
        formData.append('name', formValues.name);

        formData.append('email', formValues.email);
        formData.append('mobileNo', formValues.mobileNo);
        formData.append('state', formValues.state);
        formData.append('district', formValues.district);
        formData.append('policeStation', formValues.policeStation);
        formData.append('pincode', formValues.pincode);
        formData.append('circleName', formValues.circleName);
        // formData.append('rank', formValues.rank);
        // formData.append('employeeNo', formValues.employeeNo);
        if (formValues.password) {
            formData.append('password', formValues.password);
        }


        try {
            const res = await AdminServices.updateAdmin(selectedData._id, formData);
            if (res.status === true) {
                alert('User Admin updated successfully');
                onSuccess();
                closeModal();
            } else {
                alert('User Admin updated successfully');
            }
        } catch (error) {
            console.error('Failed to update User Operator', error);
            if (error.res && error.res.data && error.res.data.status === false) {
                alert('Email or Mobile number already exists.');
            } else {
                alert(error.response.data.message || 'An error occurred while creating the Operator user.');
            }
        }
    };

    return (
        <div className="modal fade edit-box show d-block" id="editModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" role="main">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Admin User Edit</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={closeModal} aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        <div className="container-box px-5">
                            <div className="container-box-inner">
                                <div className="page-details">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>User Type</label>
                                                    <select className="form-control" value={formValues.role}
                                                        onChange={handleInputChange} name="role">
                                                        <option value="">--Select Type--</option>
                                                        <option value="Admin">Admin</option>
                                                        <option value="User">User</option>
                                                        <option value="CO">Circle Officer</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        value={formValues.name}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={formValues.email}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Mobile No.</label>
                                                    <input
                                                        type="text"
                                                        name="mobileNo"
                                                        maxLength={10}
                                                        value={formValues.mobileNo}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>State</label>
                                                    <select
                                                        className="form-control"
                                                        name="state"
                                                        value={formValues.state}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">--Select State--</option>
                                                        {states.map((state) => (
                                                            <option key={state._id} value={state._id}>
                                                                {state.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>District</label>
                                                    <select
                                                        className="form-control"
                                                        name="district"
                                                        required
                                                        value={formValues.district}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">--Select District--</option>
                                                        {districts.map((district) => (
                                                            <option key={district._id} value={district._id}>
                                                                {district.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {formValues.role === 'CO' && (
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="input-field">
                                                        <label>Circle Name</label>
                                                        <select
                                                            className="form-control"
                                                            name="circleName"
                                                            // required
                                                            value={formValues.circleName}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">--Select Circle Name--</option>
                                                            {circle.map((circle) => (
                                                                <option key={circle} value={circle}>
                                                                    {circle}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Conditionally show "Police Station" if the role is not 'CO' */}
                                            {formValues.role !== 'CO' && (
                                                <div className="col-lg-4 col-md-6">
                                                    <div className="input-field">
                                                        <label>Police Station</label>
                                                        <select
                                                            className="form-control"
                                                            name="policeStation"
                                                            required
                                                            value={formValues.policeStation}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="">--Select Police Station--</option>
                                                            {policeStations.map((policeStation) => (
                                                                <option key={policeStation._id} value={policeStation._id}>
                                                                    {policeStation.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Pincode</label>
                                                    <input
                                                        type="text"
                                                        name="pincode"
                                                        maxLength={6}
                                                        value={formValues.pincode} // Correctly bind the value to formValues.pincode
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Rank</label>
                                                    <input
                                                        type="text"
                                                        name="rank"
                                                        value={formValues.rank}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Employee No.</label>
                                                    <input
                                                        type="text"
                                                        name="employeeNo"
                                                        value={formValues.employeeNo}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-lg-4 col-md-6">
                                                <div className="input-field">
                                                    <label>Password</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        maxLength={10}
                                                        value={formValues.password}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-lg-12">
                                                <div >
                                                    <button type="submit" className="submit-green-btn">
                                                        Submit
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

UpdateOperator.propTypes = {
    selectedData: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default UpdateOperator;

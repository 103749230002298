import requests from "./httpService";

const YogaBannerServices = {

    getYogaBanner: async () =>{
        return requests.get(`/yoga-banner`);
    },
    updateYogaBanner : async (id,body) =>{
        return requests.put(`/yoga-banner/${id}`,body);
    },

    createYogaBanner : async (body) =>{
        console.log(body)
        return requests.post(`/yoga-banner`,body);
    },
    deleteYogaBanner : async (id) =>{
        return requests.delete(`/yoga-banner/${id}`);
    }
  


}
export default YogaBannerServices;
import requests from "./httpService";

const UserServices = {

    // getAllUser : async () =>{
   
    //     return requests.get(`/profile`);
    // },
    getAllUser: async (page , search ) => {
        return requests.get(`/profile?page=${page}&search=${search}`);
    },
      getcircleThanaProfiles : async () =>{
   
        return requests.get(`/admin/thanaName`);
    },
    updateUser : async (id,body) =>{
        return requests.patch(`/profile/${id}`,body);
    },
    updateUserStatus : async (id,body) =>{
        return requests.patch(`/profile/${id}/status`,body);
    },
    getMyProfile : async () =>{
        return requests.get(`/userapp/profile`);
    },
    updateMe : async (body) =>{
        return requests.patch(`/userapp/updateMe`,body);
    },
    createAdmin : async (body) =>{
        console.log(body)
        return requests.post(`/userapp/`,body);
    },
    updatePassword : async (body) =>{
        return requests.patch(`/userapp/change-password`,body);
    },
    dashboard : async () =>{
        return requests.get(`/userapp/dashboard`);
    },
    getAllAdmin : async () =>{
        return requests.get(`/userapp`);
    },
    getAllThanaAdmin : async () =>{
        return requests.get(`/userapp/get`);
    },
    getState : async () =>{
        return requests.get(`/state`);
    },
    getDistrictByState: async (id,body) =>{
        return requests.get(`/state/${id}`,body);
    },
    getThanaByDistrict: async (id,body) =>{
        return requests.get(`/state/districts/${id}`,body);
    },
    getCircleByDistrict: async (id,body) =>{
        return requests.get(`/state/circle-names/${id}`,body);
    },
    updateAdmin: async (id,body) =>{
        return requests.patch(`/userapp/${id}`,body);
    },
    deleteAdmin: async (id,body) =>{
        return requests.delete(`/userapp/${id}`,body);
    },
    login: async (body) =>{
        return requests.post(`/userapp/login`,body);
    },
   
    

   


}

export default UserServices;



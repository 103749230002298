import React, { useEffect, useState } from 'react';
import useAsync from '../hooks/useAsync';
import AdminServices from '../services/AdminServices';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

function Dashboard() {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
    seriesTrainer: [],
    seriesApointment: [],
    seriesService: [],
  });

  const { data, error, isLoading } = useAsync(AdminServices.dashboardUser);
  console.log('>>>>>>',data);

  useEffect(() => {
    if (data && data.data) {
      const months = Array.from({ length: 12 }, (_, index) => moment().month(index).format('MMM'));
      const userCounts = new Array(12).fill(0);
      const userCounts2 = new Array(12).fill(0);
      const userCounts3 = new Array(12).fill(0);
      const userCounts4 = new Array(12).fill(0);

      data.data.user?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts[month]++;
      });

      data.data.trainer?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts2[month]++;
      });

      data.data.serviceCenters?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts3[month]++;
      });

      data.data.apointment?.forEach(user => {
        const month = moment(user.createdAt).month();
        userCounts4[month]++;
      });

      setChartData({
        categories: months,
        series: [{ name: 'User', data: userCounts }],
        seriesTrainer: [{ name: 'Trainer', data: userCounts2 }],
        seriesApointment: [{ name: 'Apointment', data: userCounts4 }],
        seriesService: [{ name: 'Service Center', data: userCounts3 }],
      });
    }
  }, [data]);

  const chartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
    },
  };

  return (
    <div className="main_container">
      {/* /top navigation */}
      {/* page content */}
      <div className="right_col " role="main">
        <div className="top-sec-heading">
          <h3>Welcome, {localStorage.getItem('name')}.</h3>
          <p>Hello, here is what happening with your app today.</p>
        </div>
        <div className="container-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart.svg" alt="" /></span>
              <p>Statistics</p>
            </div>
          </div>
          <div className="container-box-inner">
            <div className="row">
              <div className="col-md-4">
                <div className="g-box g-box1">
                  <a href="javascript:void(0)">
                  <p>Total User</p><h4>{data?.data?.totalProfiles}</h4>
                    <p>Today User</p>
                    <div className="g-box-inner">
                      
                      <h4>{data?.data?.todayProfiles}</h4>
                      <img src="img/total-customer.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="g-box g-box2">
                  <a href="javascript:void(0)">
                  <p>Total Pending Verification</p>  <h4>{data?.data?.totalUnverified}</h4>   
                    <p>Today Pending Verification</p>
                    <div className="g-box-inner">
                    
                      <h4>{data?.data?.todayUnverified}</h4>
                      <img src="img/query.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
            
              <div className="col-md-4">
                <div className="g-box g-box7">
                  <a href="javascript:void(0)">
                  <p>Total Verified</p>   <h4>{data?.data?.totalVerified}</h4>
                     <p>Today Verified</p>  
                    <div className="g-box-inner">
                      <h4>{data?.data?.todayVerified}</h4>
                      <img src="img/query.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="g-box g-box8">
                  <a href="javascript:void(0)">
                    <p>Total Operator User</p>
                    <div className="g-box-inner">
                      <h4>{data?.data?.totalUserOperators}</h4>
                      <img src="img/admin-user.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Verification</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.series}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div> */}
        {/* Uncomment the following sections if needed */}
        {/* <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Trainer</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesTrainer}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Service Center</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesService}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div>
        <div className="container-box qr-code-box">
          <div className="container-box-top-header">
            <div className="container-box-top-header-left">
              <span><img src="img/chart2.svg" alt="" /></span>
              <p>Total Apointment</p>
            </div>
          </div>
          <div className="container-box-inner mt-3 pt-4">
            <div className="qr-code-status mt-4">
              <ReactApexChart
                options={chartOptions}
                series={chartData.seriesApointment}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </div> */}
      </div>
      {/* /page content */}
    </div>
  );
}

export default Dashboard;
